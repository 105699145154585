// 
// 
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.img-landing-banner{
  transform: translateY(-20rem)!important;
}

.img-landing-banner:hover{
  transform: translateY(-24rem)!important;
}

.margin-top-3rem {
  margin-top: 3rem!important;
}